import CryptoJS from 'crypto-js';
const keys = 'UqA482S68ffsQ1Yd';
export default {
    //加密
    encrypt(word, keyStr) {
        keyStr = keyStr ? keyStr : keys; //判断是否存在ksy，不存在就用定义好的key
        var b = CryptoJS.enc.Base64; //需要base64加密的
        var key = CryptoJS.enc.Utf8.parse(keyStr);
        var srcs = CryptoJS.enc.Utf8.parse(word);
        var encrypted = CryptoJS.AES.encrypt(srcs, key, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 });
        //return encrypted.toString();
        return b.stringify(encrypted.ciphertext);
    },
    //解密
    decrypt(word, keyStr) {
        keyStr = keyStr ? keyStr : keys;
        var key = CryptoJS.enc.Utf8.parse(keyStr);
        var decrypt = CryptoJS.AES.decrypt(word, key, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 });
        return CryptoJS.enc.Utf8.stringify(decrypt).toString();
    }
}    