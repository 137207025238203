// 综合（工作台）路由管理
export default{
    path: 'workbench',
    name: 'workbench',
    component: () => import('@/components/RouterView'),
    redirect: '/manage/workbench/dashboard',
    meta: {title: '运营管理'},
    children: [{
        path: 'accountInfo',
        name: 'accountInfo',
        component: () => import('@/views/manage/workbench/accountInfo'),
        meta: {title: '账户信息'}
    },{
        path: 'auth',
        name: 'auth',
        component: () => import('@/views/manage/workbench/auth'),
        meta: {title: '认证管理'}
    },{
        path: 'site',
        name: 'site',
        component: () => import('@/components/RouterView'),
        meta: {title: '小站管理'},
        children:[{
            path:'baseInfo',
            component: () => import('@/views/manage/workbench/site/baseInfo'),
            meta: {title: '小站管理'},
        },{
            path:'music',
            component: () => import('@/views/manage/workbench/site/music'),
            meta: {title: '小站管理'},
        },{
            path:'media',
            component: () => import('@/views/manage/workbench/site/media'),
            meta: {title: '小站管理'},
        },{
            path:'video',
            component: () => import('@/views/manage/workbench/site/video'),
            meta: {title: '小站管理'},
        },{
            path:'release',
            component: () => import('@/views/manage/workbench/site/release'),
            meta: {title: '小站管理'},
        }]
    },{
        path: 'subUser',
        name: 'subUser',
        component: () => import('@/views/manage/workbench/subUser'),
        meta: {title: '工作人员管理'}
    },{
        path: 'publicity',
        name: 'publicity',
        component: () => import('@/views/manage/workbench/publicity'),
        meta: {title: '已排期宣推资源'}
    },{
        path: 'fansNotice',
        name: 'fansNotice',
		component: () => import('@/components/RouterView'),        
        meta: {title: '粉丝通告'},
		children:[{
		    path:'list',
		    component: () => import('@/views/manage/workbench/fansNotice/list'),
		    meta: {title: '粉丝通告'},
		}]
    }]
}