import Vue from 'vue'
import VueRouter from 'vue-router'
import cookie from 'js-cookie'
import store from '@/store'
import {MessageBox} from 'element-ui';

import registerRouter from './modules/register'; //注册管理路由
import activityRouter from './modules/activity'; //演出管理路由
import workbenchRouter from './modules/workbench'; //工作台路由管理
import raiseRouter from './modules/raise';//众筹管理路由
import financeRouter from './modules/finance'; //财务管理路由
import dashboardRouter from './modules/dashboard'; //数据分析路由
import goodsRouter from './modules/goods'; //数据分析路由
import entranceRouter from './modules/entrance'; //入场数据
import TicketcheckRouter from './modules/Ticketcheck'; //入场数据
import entryDataRouter from './modules/entryData'; //入场数据
// import modules from './modules'

Vue.use(VueRouter)

const routes = [
    {
        path: '/login',
        name: 'login',
        component: () => import('@/views/login'),
        meta: {
            requireAuth: false
        }
    },{
        path:'/login/auto',
        name: 'login-auto',
        component: () => import('@/views/login/auto'),
        meta: {
            requireAuth: false
        }
    },{
        path:'/login/lostpwd',
        name: 'login-lostpwd',
        component: () => import('@/views/login/lostpwd'),
        meta: {
            requireAuth: false
        }
    },{
        path:'/error',
        component: () => import('@/layout/default'),
        meta: {
            requireAuth: false
        },
        children:[{
            path: '404',
            name: '404',
            component: () => import('@/views/error/404'),
            meta: {
                breadcrumb: false
            }
        }]
        
    },{
        path: '/',
        component: () => import('@/views/index'),
        redirect: '/index',
        meta: {
            requireAuth: false
        },
        children:[{
            path: 'index',
            name: 'index',
            component: () => import('@/views/index'),
        }]
    },
    {
        path: '/showstartRelease',
        component: () => import('@/layout/default'),
        children:[{
            path:'',
            name: 'showstartRelease',
            component: () => import('@/views/showstartRelease'),
        }]
    },
    {
        path: '/ticketCode',
        component: () => import('@/layout/default'),
        children:[{
            path:'',
            name: 'ticketCode',
            component: () => import('@/views/ticketCode'),
        }]
    },
    {
        path: '/manage',
        component: () => import('@/layout/manage'),
        redirect: '/manage/home',
        meta: {
            requireAuth: true
        },
        children: [
            {
                path: 'home',
                name: 'manageHome',
                component: () => import('@/views/manage/home')
            },
            activityRouter,
            workbenchRouter,
            raiseRouter,
            financeRouter,
			dashboardRouter,
            goodsRouter
        ]
    },
    registerRouter,
    entranceRouter,
    TicketcheckRouter,
    entryDataRouter,
    {path: '*', redirect: '/error/404', meta: {breadcrumb: false}}
]

//使用push()、replace()进行导航时，不能重复导航到当前路由。
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
    mode: 'history',
    scrollBehavior: () => ({ x: 0, y: 0 }),
    routes
})

//登录拦截
router.beforeEach((to, from, next) => {
    if (to.matched.some(r => r.meta.requireAuth)) {
        if (to.path === '/login') return next()
        const _sign = cookie.get('CUSUT');
        if (_sign) {
            if (!store.state.CUSUT) {
                store.commit('setCUSUT', _sign);
            }
            next();

        } else {
            next({
                path: '/login',
                query: {
                    redirect: to.fullPath
                }
            })
        }
    }else if(to.matched.some(r => r.meta.secretKey)) {
        const _secretKey = cookie.get('secretKey');
        if( _secretKey ){
            next();
        }else{
            next({
                path: '/entrance/login',
                query: {
                    redirect: to.fullPath
                }
            })
        }
    }else if(to.matched.some(r => r.meta.ticketcheckSecretKey)) {
        const _secretKey = cookie.get('ticketcheckSecretKey');
        if( _secretKey ){
            next();
        }else{
            next({
                path: '/Ticketcheck/login',
                query: {
                    redirect: to.fullPath
                }
            })
        }
    }else if(to.matched.some(r => r.meta.entryDataSecretKey)) {
        const _secretKey = cookie.get('entryDataSecretKey');
        if( _secretKey ){
            next();
        }else{
            next({
                path: '/entryData/login',
                query: {
                    redirect: to.fullPath
                }
            })
        }
    }else {
        next()
    }
})

//异常处理
router.onError(async error => {
    if (error.toString().indexOf('ChunkLoadError') === -1) {
        return;
    }

    const flag = await MessageBox.confirm('服务器版本已经更新，页面刷新后可以恢复正常使用', '提示', {
        confirmButtonText: '立即刷新',
        cancelButtonText: '取消',
        showClose: false,
        closeOnClickModal: false,
        closeOnPressEscape: false,
        center: true
    }).catch(() => 'cancel');

    if (flag === 'cancel') {
        return;
    }

    window.location.reload();
})

export default router