// 入场数据路由管理
export default{
    path: '/Ticketcheck',
    component: () => import('@/components/RouterView'),
    meta: {
        requireAuth: false
    },
    children:[{
        path:'',
        component: () => import('@/views/Ticketcheck/statistics.vue'),
        meta: {
            ticketcheckSecretKey: true
        },
    },{
        path:'login',
        component: () => import('@/views/Ticketcheck/login.vue'),
    }]
}