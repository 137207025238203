class Media {
    constructor(){
        let audio;

        try {
            audio = new Audio();
        } catch (error) {
            return this;
        }

        let _eventHandlers = {};
        audio.on = this.on = (type, listener) => {
            audio.addEventListener(type, listener, false);
            let listeners = _eventHandlers[type] || [];
            listeners.push(listener);
            return audio;
        };
        audio.off = this.off = (type, listener) => {
            if (!type && !listener) {
                for (let key in _eventHandlers) {
                    _eventHandlers[key].forEach(listener => {
                        audio.removeEventListener(key, listener, false);
                    });
                }
            } else {
                audio.removeEventListener(type, listener, false);
            }
            return audio;
        };

        this.audio = audio;
        this._initEvents();
    }
    _initEvents(){
        let { audio } = this;

        audio.on("loadstart", () => {
            console.log("开始加载");
            this.setState("loadstart");
        }).on("error", e => {
            console.log("错了");
            this.setState("error");
        }).on("playing", () => {
            console.log("播放中");
            this.setState("playing");
        }).on("pause", () => {
            console.log("暂停");
            this.setState("pause");
        }).on("ended", () => {
            console.log("结束");
            this.setState("ended");
        }).on("waiting", () => {
            console.log("等待");
            this.setState("waiting");
        }).on("loadeddata", () => {
            console.log("加载数据");
            this.setState("loadeddata");
        }).on("progress", e => {
            
        }).on("timeupdate", () => {
            
        });
    }
    //实则播放链接
    setUrl(url) {
        if (url) {
            this.audio.src = this._url = url;
            this.audio.load();
        }
        return this;
    }
    //获取当前播放链接
    getUrl(){
        return this._url;
    }
    //设置歌曲信息
    setSong(song){
        this._song = song;
    }
    //获取歌曲信息
    getSong(){
        return this._song;
    }
    //播放音乐
    play(song){
        const src = song && song.playUrl || '';
        if( src == this.getUrl() && this.getState() == "playing" ){
            return;
        }
        if( src && src != this.getUrl() || src && this.getState() == "error" ){
            this.setSong(song);
            this.setUrl(src);
        }
        let promise = this.audio.play();
        if (promise.catch) {
            promise.catch(e => {
                console.log("播放错误")
            });
        }
        return this;
    }
    //暂停音乐
    pause() {
        this.audio.pause();
        return this;
    }
    //设置播放状态
    setState(state){
        this._state = state;
    }
    //获取播放器状态
    getState(){
        return this._state;
    }
    //获取时长
    getDuration(){
        return this.audio.duration;
    }
    //获取当前播放时长
    getCurrentTime(){
        return this.audio.currentTime;
    }
    //停止、销毁
    destroy(){
        this.pause();
        this._url = '';
    }
}
const player = new Media();

export default player