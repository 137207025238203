// 数据分析路由
export default {
    path: 'dashboard',
	name: 'dashboard',
    component: () => import('@/components/RouterView'),
    redirect: '/manage/dashboard/hostsales',
    meta: {title: '数据分析'},    
	children: [{
	    path: 'hostsales',
	    name: 'hostsales',
	    component: () => import('@/views/manage/dashboard/hostsales'),
	    meta: {title: '主办活动销售'},
	},{
        path:'hostsales/list/:date',
        component: () => import('@/views/manage/dashboard/hostsales/list'),
        meta: {title: '主办活动销售'}, 
		props:true,
    },{
        path:'hostsales/chart/:activityId/:date',
        component: () => import('@/views/manage/dashboard/hostsales/chart'),
        meta: {title: '主办活动销售'}, 
		props:true,
    },{
	    path: 'fans',
	    name: 'fans',
	    component: () => import('@/views/manage/dashboard/fans'),
	    meta: {title: '粉丝增长'},
	},{
	    path: 'fansdata',
	    name: 'fansdata',
	    component: () => import('@/views/manage/dashboard/fansdata'),
	    meta: {title: '粉丝画像'},
	}]
}