// 财务管理路由
export default {
    path: 'finance',
    component: () => import('@/components/RouterView'),
    redirect: 'noRedirect',
    meta: {title: '财务管理'},
    children:[{
        path: 'activity',
        component: () => import('@/components/RouterView'),
        redirect: 'noRedirect',
        children: [{
            path: 'receivables',
            component: () => import('@/views/manage/finance/activity/receivables'),
            meta: {title: '演出收款信息'},
        },{
            path: 'settlement',
            component: () => import('@/views/manage/finance/activity/settlement'),
            meta: {title: '演出结款申请'},
        }]
    },{
        path: 'raise',
        component: () => import('@/components/RouterView'),
        redirect: 'noRedirect',
        children: [{
            path: 'receivables',
            component: () => import('@/views/manage/finance/raise/receivables'),
            meta: {title: '众筹收款信息'},
        },{
            path: 'settlement',
            component: () => import('@/views/manage/finance/raise/settlement'),
            meta: {title: '众筹结款申请'},
        }]
    }]
}