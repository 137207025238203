// 众筹路由管理
export default{
    path: 'raise',
    name: 'raise',
    component: () => import('@/components/RouterView'),
    redirect: '/manage/raise/list',
    meta: {title: '众筹管理'},
    children: [{
        path: 'list',
        name: 'raiseList',
        component: () => import('@/views/manage/raise/list'),
        meta: {title: '众筹列表',showTitle:false}
    },{
        path: 'draft',
        name: 'draftList',
        component: () => import('@/views/manage/raise/draft'),
        meta: {title: '众筹草稿'}
    },{
        path:'edit/:id',
        component: () => import('@/views/manage/raise/edit/edit.vue'),
        meta: {title: '众筹详情',showTitle:false},
        props:true
    },{
        path:'edit/step1/:id',
        component: () => import('@/views/manage/raise/edit/step1.vue'),
        meta: {title: '发布众筹',showTitle:false},
        props:true
    },{
        path:'edit/step2/:id',
        component: () => import('@/views/manage/raise/edit/step2.vue'),
        meta: {title: '发布众筹',showTitle:false},
        props:true
    },{
        path:'add/step1',
        component: () => import('@/views/manage/raise/edit/step1.vue'),
        meta: {title: '发布众筹',showTitle:false},
    },{
        path:'edit/finish/:id',
        component: () => import('@/views/manage/raise/edit/finish.vue'),
        meta: {title: '发布众筹'},
        props:true
    },{
        path:'edit/statistics/:id',
        component: () => import('@/views/manage/raise/edit/statistics'),
        meta: {title: '众筹详情',showTitle:false},
        props:true
    }]
}