// 入场数据路由管理
export default{
    path: '/entryData',
    component: () => import('@/components/RouterView'),
    meta: {
        requireAuth: false
    },
    children:[{
        path:'',
        component: () => import('@/views/entryData/statistics.vue'),
        meta: {
            entryDataSecretKey: true
        },
    },{
        path:'login',
        component: () => import('@/views/entryData/login.vue'),
    }]
}