// 商品路由管理
export default{
    path: 'goods',
    name: 'goods',
    component: () => import('@/components/RouterView'),
    redirect: '/manage/goods/list',
    meta: {title: '商品管理'},
    children: [{
        path: 'list',
        name: 'goodsList',
        component: () => import('@/views/manage/goods/list'),
        meta: {title: '商品列表',showTitle:true}
    }
    ,{
        path: 'deliver',
        name: 'goodsDeliver',
        component: () => import('@/views/manage/goods/deliver'),
        meta: {title: '商品发货',showTitle:true}
    }]
}