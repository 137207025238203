// 入场数据路由管理
export default{
    path: '/entrance',
    component: () => import('@/components/RouterView'),
    redirect: '/entrance/statistics',
    meta: {
        requireAuth: false
    },
    children:[{
        path:'statistics',
        component: () => import('@/views/entrance/statistics.vue'),
        meta: {
            secretKey: true
        },
    },{
        path:'login',
        component: () => import('@/views/entrance/login.vue'),
    }]
}