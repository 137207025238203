import request from '@/assets/js/request';
import store from '@/store';
import utils from '@/assets/js/utils';

const {VUE_APP_UPLOADFILE_URL} = process.env;
const uploadFileUrl = '';//VUE_APP_UPLOADFILE_URL;
/**
 * @description 获取用户信息
 */
// export function getUserInfo(id) {
//     return request({
//         url: '/userInfo/getById',
//         method: 'post',
//         params:{userId:id}
//     });
//     // const { state,result } = res.data;
//     // if (state == 1 && result) {
//     //     store.commit('setUserInfo',result);
//     // }
//     // return res
// }

/**
 * @description 获取登录用户拥有的一二级菜单列表
 */
export async function getMenuList() {
    const data = await request({
        url: '/user/login/getMenu',
        method: 'post',
        params: {bId: store.state.userInfo.id}
    });
    const { state,result } = data.data;
    if (state == 1 && result) {
        store.commit('setMenuList',result.menu);
		console.log(result.menu)
        store.commit('setPriviList',result.privileges);
    }
}

/**
 * @description 获取所有权限列表
 */

// export function getPrivileges(params){
//     return request({
//         url: '/adminPrivilege/list',
//         method: 'post',
//         data: params || {parentId:""}
//     })
// }

/**
 * @description 获取所有角色
 */

// export function getRoleAll(){
//     return request({
//         url: '/adminRole/list',
//         method: 'post',
//         data: {}
//     })
// }

/**
 * @description 上传图片
 *  @param {*} formData
 */

export function uploadImg(formData, onUploadProgress){
    return request({
        auth:false,
        url: uploadFileUrl+'/file/upimg',//'https://dev2-uploadfile.showstart.com/file/upimg',
        method: 'post',
        data: formData,
        params:{terminal:5},
        onUploadProgress: onUploadProgress || function() {}
    })
}

/**
 * @description 图片上传（base64）
 * @param {*} formData
 */
export function uploadImgByBase64(formData, onUploadProgress) {
    return request({
        auth:false,
        url: uploadFileUrl+'/file/upimg64',
        method: 'post',
        // timeout: 60 * 60 * 1000,
        data: formData,
        params:{terminal:5},
        onUploadProgress: onUploadProgress || function() {}
    });
}

/**
 * @description 上传音频
 * @param {*} formData
 */
export function uploadAudio(formData, onUploadProgress) {
    return request({
        auth:false,
        url: uploadFileUrl+'/file/upaudio',
        method: 'post',
        // timeout: 60 * 60 * 1000,
        data: formData,
        params:{terminal:5},
        onUploadProgress: onUploadProgress || function() {}
    });
}


/**
 * @description 下载临时文件
 * @param {*} filePath 文件下载路径
 */
export async function downloadFileTmp(filePath,fileName) {
    const res = await request({
        auth:false,
        url: uploadFileUrl+'/file/downfiletmp',
        method: 'post',
        // timeout: 60 * 60 * 1000,
        params:{filePath:filePath,terminal:5},
        responseType: 'blob'
    });
    utils.exportFile(res.data,fileName);
}

/**
 * @description 上传临时文件
 * @param {*} formData
 */
export function uploadFileTmp(formData,onUploadProgress) {
    return request({
        auth:false,
        url:uploadFileUrl+'/file/upfiletmp',
        method: 'post',
        // timeout: 60 * 60 * 1000,
        data: formData,
        params:{privileges:0,terminal:5},
        onUploadProgress: onUploadProgress || function() {}
    });
}

/**
 * @description 上传正式文件
 * @param {*} formData
 */
export function uploadFile(formData,onUploadProgress) {
    return request({
        auth:false,
        url: uploadFileUrl+'/file/upfile',
        method: 'post',
        // timeout: 60 * 60 * 1000,
        data: formData,
        params:{privileges:0,terminal:5,ownerId:1},
        onUploadProgress: onUploadProgress || function() {}
    });
}

/**
 * @description 下载正式文件、图片等
 * @param {*} filePath 文件下载路径
 */
export async function downloadFile(filePath,fileName) {
    const res = await request({
        auth:false,
        url: uploadFileUrl+'/file/downfile',
        method: 'post',
        // timeout: 60 * 60 * 1000,
        params:{filePath:filePath,terminal:5},
        responseType: 'blob'
    });
    utils.exportFile(res.data,fileName);
}

/**
 * @description 查询用户（可用于查询选择）
 * @param {*} queryCount 查询个数，默认20个
 * @param {*} queryValue 查询值（用户名称或者用户id）
 * @param {*} userType 用户类型:-1=不限，1=乐迷，2=艺人，3=场地，5=厂牌，默认-1
 */
export async function fetchUser(params) {
    return request({
        url: '/userInfo/query',
        method: 'post',
        data: params
    });
}

/**
 * @description 获取省份
 */
export function fetchProvince() {
    return request({
        url: '/city/provinces',
        method: 'post',
    });
}

/**
 * @description 获取城市
 * @param {*} provinceCode 省份code
 */
export function fetchCityList(provinceCode) {
    return request({
        url: '/city/cityList',
        method: 'post',
        params: {provinceCode:provinceCode}
    });
}

/**
 * @description 获取省份城市联级
 */
export async function fetchAllCity() {
    let res = await request({
        url: '/allCity',
        method: 'post',
    })
    const { data:{result} } = res;
    if(result){
        res.data.result.map(item => {
            item.cityList.map( v => {
                v.code = v.id;
            })
            item.cityList = Array.from(item.cityList).sort((x,y) => x.id - y.id);
        })
    }
    return res;
}


/**
 * @description 获取风格列表
 */
export function fetchStyleList() {
    return request({
        url: '/style/list',
        method: 'post',
    });
}

/**
 * @description 获取风格标签列表
 */
export function fetchTagList(styleId) {
    return request({
        url: '/style/tags',
        method: 'post',
        params: {styleId:styleId}
    });
}

/**
 * @description 获取风格tag联级
 */
export function fetchAllTag() {
    return request({
        url: '/style/all',
        method: 'post',
    });
}


/**
 * @description 获取短信验证码
 * @param {*} biz 业务代码
 * @param {*} phone 手机号
 * @param {*} randStr 腾讯云随机数
 * @param {*} ticket 腾讯云返回的票据
 * 
 */
export function fetchCode(params) {
    return request({
        url: '/code',
        method: 'post',
        params:params,
    });
}

/**
 * @description 获取所有快递公司
 */
export function fetchExpress() {
    return request({
        url: '/express/all',
        method: 'post',
    });
}

/**
 * @description 获取银行列表
 */
export function fetchBank() {
    return request({
        url: '/bank/all',
        method: 'post',
    });
}

/**
 * @description 保存腾讯云上传结果
 * @param {*} bucket 文件存储桶
 * @param {*} key 文件key
 * @param {*} resourceId 资源ID
 * @param {*} signUrl 代签名访问url
 */
export function fileTask(params){
    return request({
        url: '/video/save/tencent',
        method: 'post',
        data: {...params}
    });
}

/**
 * @description 获取验证码开关
 */
 export function verificationSwitch(){
    return request({
        url: '/common/verificationSwitch',
        method: 'post',
    });
}
/**
 * @description 获取服务模板
 * @param {*} type 1：演出 2：周边 获取对应的所有服务模板
 */
 export function templateALL(params){
    return request({
        url: '/template/all',
        method: 'post',
        params: {...params}
    });
}

/**
 * @description 获取服务模板类型
 */
// export function fetchServiceTemplateType() {
//     return [{
//         value: 1,
//         label: '售票方式'
//     }, {
//         value: 2,
//         label: '场地席位'
//     }, {
//         value: 3,
//         label: '转赠'
//     }, {
//         value: 4,
//         label: '退票政策'
//     }, {
//         value: 5,
//         label: '儿童购票'
//     }, {
//         value: 6,
//         label: '演出时长'
//     }, {
//         value: 7,
//         label: '入场规则'
//     }, {
//         value: 8,
//         label: '学生购票'
//     }
// 	, {
// 	    value: 9,
// 	    label: '购票规则'
// 	}, {
//         value: 0,
//         label: '其他'
//     }]
// }
export function fetchServiceTemplateType() {
    return [{
        value: 1,
        label: '服务说明'
    }, {
        value: 3,
        label: '其它'
    }, {
        value: 4,
        label: '售后相关'
    }, {
        value: 6,
        label: '观演规则'
    }, {
        value: 7,
        label: '入场规则'
    }
	, {
	    value: 9,
	    label: '购票规则'
	}]
}


/**
 * @description 获取场地类型列表
 */
export function fetchSiteTagList() {
    return request({
        url: '/style/siteType/all',
        method: 'post',
    });
}