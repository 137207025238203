import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import Element from 'element-ui';
import Request from '@/assets/js/request';
import Utils from '@/assets/js/utils';
import Player from '@/assets/js/player';
import Message from '@/assets/js/resetMessage';
import ls from 'local-storage'
// import BaiduMap from 'vue-baidu-map';

import 'element-ui/lib/theme-chalk/display.css'
import '@/assets/theme/index.css'
import "@/assets/iconfont/iconfont.css";

import '@/assets/js/filter';

Vue.config.productionTip = false

Vue.use(Element);
// Vue.use(BaiduMap, {
//   ak: 'Y5qE51IMbh9Sdq2NAmMDUGGw'
// });

Vue.prototype.$request = Request;
Vue.prototype.$utils = Utils;
Vue.prototype.$player = Player;
Vue.prototype.$userType = new Map([
  [1, '乐迷'],
  [2, '艺人'],
  [3, '场地'],
  [4, '赞助商'],
  [5, '厂牌']
])
Vue.prototype.$message = Message;


//跳转秀发发行
const { VUE_APP_RELEASEWEB_URL } = process.env;

Vue.prototype.$release = async (uri) => {
  const redirect = encodeURIComponent(uri || '/');
  const res = await Request({
    url: '/user/login/releaseLogin',
    method: 'post',
  });
  const { state, result, msg } = res.data;
  if (state != 1) {
    return Message.error(msg)
  } else {
    window.open(`${VUE_APP_RELEASEWEB_URL}/autologin?sskey=${result || ''}&redirect=${redirect}`)
  }
}



new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')
