// 演出路由管理
export default{
    path: 'activity',
    name: 'activity',
    component: () => import('@/components/RouterView'),
    redirect: '/manage/activity/list',
    meta: {title: '演出管理'},
    children: [{
        path: 'list',
        name: 'activityList',
        component: () => import('@/views/manage/activity/list'),
        meta: {title: '演出列表',showTitle:false}
    },{
        path: 'draft',
        component: () => import('@/views/manage/activity/draft'),
        meta: {title: '演出草稿'}
    },{
        path:'add/step1',
        component: () => import('@/views/manage/activity/edit/step1.vue'),
        meta: {title: '发布演出',showTitle:false}
    },{
        path:'edit/step1/:id',
        component: () => import('@/views/manage/activity/edit/step1.vue'),
        meta: {title: '发布演出',showTitle:false},
        props:true
    },{
        path:'edit/step2/:id',
        component: () => import('@/views/manage/activity/edit/step2.vue'),
        meta: {title: '发布演出',showTitle:false},
        props:true
    },{
        path:'edit/step3/:id',
        component: () => import('@/views/manage/activity/edit/step3.vue'),
        meta: {title: '发布演出',showTitle:false},
        props:true
    },{
        path:'edit/finish/:id',
        component: () => import('@/views/manage/activity/edit/finish.vue'),
        meta: {title: '发布演出',showTitle:false},
        props:true
    },{
        path:'edit/:id',
        component: () => import('@/views/manage/activity/edit/edit.vue'),
        meta: {title: '演出详情',showTitle:false},
        props:true
    },{
        path:'edit/statistics/:id',
        component: () => import('@/views/manage/activity/edit/statistics'),
        meta: {title: '数据统计',showTitle:false},
        props:true
    },{
        path:'edit/statisticschart/:id',
        component: () => import('@/views/manage/activity/edit/statistics/statisticschart.vue'),
        meta: {title: '分时统计',showTitle:false},
        props:true
    },{
        path:'edit/statistics/userindex/:id',
        component: () => import('@/views/manage/activity/edit/statistics/userindex'),
        meta: {title: '购票用户',showTitle:false},
        props:true
    },{
        path:'tour',
        component: () => import('@/views/manage/activity/tour'),
        meta: {title: '巡演管理'},
    },{
        path:'edit/seat/:id',
        component: () => import('@/views/manage/activity/edit/seat'),
        meta: {title: '座位管理',showTitle:false},
		name: 'editSeat',
        props:true
    },{
        path:'edit/num/:id',
        component: () => import('@/views/manage/activity/edit/num'),
        meta: {title: '排队管理',showTitle:false},
		name: 'editNum',
        props:true
    }]
}