import ls from 'local-storage'

export default {
    // 加载js
    loadScript:function(params, callback) {
        if (document.getElementById(params.id)) {
            callback ? callback() : null;
        } else {
            const script = document.createElement('script');
            script.async = true;
            script.src = params.url;
            script.id = params.id;
            script.onload = () => {
                callback ? callback() : null;
            };
            document.head.appendChild(script);
        }
    },
    //uuid
    uuid:function( _len ) {
        var str = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz" + ( new Date() ).getTime(), len = _len || 20;
        
        var temp = [];
    
        for( var i = 0; i < len; i ++ ) {
            temp.push( str.charAt( Math.floor( Math.random() * str.length ) ) );
        }
        
        return temp.join( "" );
    },
    //get形式url组装
    objArgs: function(url,obj) {
        let reurl = url + '?'
        let keys = Object.keys(obj)
        keys.forEach((key) => {
            //if( obj[key] ){
                reurl += key + '=' + encodeURIComponent(obj[key]) + '&'
            //}
        })
        reurl = reurl.substring(0,reurl.length-1)
        return reurl 
    },
    //日期格式化
    formatDate:function(date, pattern) {
        var _d = date && (date.toString()).includes('-') ? date.replace(/-/g,'/') : date;
		var d = new Date(_d)
      
        if (typeof pattern === 'undefined' || pattern === null) {
          pattern = 'yyyy-MM-dd'
        }
        var val = {
          'M+': d.getMonth() + 1, // 月份
          'd+': d.getDate(), // 日
          'H+': d.getHours(), // 小时
          'm+': d.getMinutes(), // 分钟
          's+': d.getSeconds() // 秒
        }
        if (/(y+)/.test(pattern)) {
          var year = String(d.getFullYear())
          pattern = pattern.replace(RegExp.$1, year.substr(4 - RegExp.$1.length))
        }
        for (var i in val) {
          if (new RegExp('(' + i + ')').test(pattern)) {
            // 如果只匹配一个值,如果该数<10,那么不加0,否则仍然为两位数,否则如果为2位数,<10则补0
            var temp = (RegExp.$1.length === 1) ? val[i] : ('00' + val[i]).substr(String(val[i]).length)
            pattern = pattern.replace(RegExp.$1, temp)
          }
        }
        //console.log( pattern )
        return pattern
    },
    //验证手机号码
    validatePhone: function(rule, value, callback) {
        const _reg = /^\d{11}$/;
        if (!_reg.test(value)) {
            callback(new Error("请填写11位的手机号"));
        } else {
            callback();
        }
    },
    //导出报表
    exportFile: function(data,fileName) {
        let blob = new Blob([data], {type: `application/${data.type};charset=utf-8`});
        // 获取heads中的filename文件名
        let downloadElement = document.createElement('a');
        // 创建下载的链接
        let href = window.URL.createObjectURL(blob);
        downloadElement.href = href;
        // 下载后文件名
        downloadElement.download = fileName;
        document.body.appendChild(downloadElement);
        // 点击下载
        downloadElement.click();
        // 下载完成移除元素
        document.body.removeChild(downloadElement);
        // 释放掉blob对象
        window.URL.revokeObjectURL(href);

    },

    //base64转blob
    base64ToBlob(base64Data){
        let arr = base64Data.split(','),
            fileType = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            l = bstr.length,
            u8Arr = new Uint8Array(l);
            
        while (l--) {
            u8Arr[l] = bstr.charCodeAt(l);
        }
        return new Blob([u8Arr], {
            type: fileType
        });
    },

    //权限判断
    have:function( url ){
        var priviLists = ls.get( 'priviList' ) || [];
        //priviLists = JSON.parse( priviLists );

        return priviLists.find(v => v === url);
    
    },

    /**
     * 获取文件后缀
     * @param {String} fileName 需要处理的文件名称
     * @returns 文件后缀名的小写 || false
     */
    getFileType:function(fileName) {
        const startIndex = fileName.lastIndexOf('.');
        if (startIndex != -1) return fileName.substring(startIndex + 1, fileName.length).toLowerCase();
        else return false;
    },

    /**
     * 根据url导出文件
     * @param {String} path 文件下载全路径
     * @param {String} fileName 需要处理的文件名称
     */
    exportFileUrl: function (path, fileName) {
        const a = document.createElement('a');
        a.id = 'downloadA';
        a.style.display = 'none';
        document.body.appendChild(a);
        a.href = path;
        a.target = '_blank';
        a.download = fileName ? fileName : '';
        a.click();
    },

    // 时间格式化
    formatTime: function(time){
        if (typeof time !== 'number' || time < 0) {
            return time
        }
    
        var hour = parseInt(time / 3600)
        time = time % 3600
        var minute = parseInt(time / 60)
        time = time % 60
        var second = time
    
        return ([hour, minute, second]).map(function (n) {
            n = parseInt(n).toString()
            return n[1] ? n : '0' + n
        }).join(':')
    },

    //时间比较
    compareDate: function(s1,s2){
        return ((new Date(s1.replace(/-/g,"/"))) >= (new Date(s2.replace(/-/g,"/"))));
    },
	 //时间比较，两个时间可以相等
	compareDate2: function(s1,s2){
	    return ((new Date(s1.replace(/-/g,"/"))) > (new Date(s2.replace(/-/g,"/"))));
	},

    //给定日期 获取周几
    getWeekDate(date){
        const now = new Date(date);
        const day = now.getDay();
        const weeks = new Array("日","一","二","三","四","五","六");
        const week = "周"+weeks[day];
        return week;
    }
}