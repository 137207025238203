//import Vue from 'vue';
import axios from 'axios';
import store from '@/store';
import router from '@/router';
import utils from '@/assets/js/utils';
import AES from '@/assets/js/AES.js';
import cookies from 'js-cookie';
import ls from 'local-storage';
var md5 = require('js-md5');

import {MessageBox} from 'element-ui';

const codeMessage = {
    200: '服务器成功返回请求的数据。',
    201: '新建或修改数据成功。',
    202: '一个请求已经进入后台排队（异步任务）。',
    204: '删除数据成功。',
    400: '发出的请求有错误，服务器没有进行新建或修改数据的操作。',
    401: '用户没有权限（令牌、用户名、密码错误）。',
    403: '用户得到授权，但是访问是被禁止的。',
    404: '发出的请求针对的是不存在的记录，服务器没有进行操作。',
    406: '请求的格式不可得。',
    410: '请求的资源被永久删除，且不会再得到的。',
    413: '文件过大，请重新上传',
    422: '当创建一个对象时，发生一个验证错误。',
    500: '服务器发生错误，请检查服务器。',
    502: '网关错误。',
    503: '服务不可用，服务器暂时过载或维护。',
    504: '网关超时。',
};

// create an axios instance
const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_API || '', // api base_url
    timeout: 10 * 60 * 1000 // request timeout
});
const appid = process.env.VUE_APP_APPID || 'fb'
const {VUE_APP_ENV} = process.env;

// request interceptor
service.interceptors.request.use(
    config => {
        // api formatt
        // if (!config.url.endsWith('.json')) {
        //     config.url += '.json';
        // }
        config.auth = config.auth !== false ? true : false;
        if( VUE_APP_ENV === 'development' ) console.log('接口地址:',config.url,'\n提交参数:',JSON.stringify(config.data || config.params));
        if(config.auth){
            let paramSource = {
                action: config.url,
                method: config.method,
                query: config.params || null,
                body: config.data || null,
                qtime: new Date().getTime(),//Date.parse(new Date()) / 1000
                ranstr: utils.uuid(8)
            }
            const _paramSource = JSON.stringify(paramSource)+'';
            const sign = md5(_paramSource).toLowerCase();

            paramSource = AES.encrypt(JSON.stringify(paramSource));
            if( VUE_APP_ENV === 'development' ) console.log('解密后的参数',JSON.parse(AES.decrypt(paramSource)));

            config.data = {
                data: paramSource,
                sign: sign,
                appid: appid,
                terminal: 'web',
                version: '1.0.0'
            };
            config.method = 'POST';
            config.url = '/api/fb';
            config.params = null;
        }

        config.headers.CUSUT = cookies.get('CUSUT') || '';
        config.headers.CUUSERREF = ls.get('token');
        config.headers.CUSYSTIME = new Date().getTime();

        // const ENCRYPT_KEY = "qscSkwfZHojhXAmSRdsGo8josezXRJuWI0lQDFwkV52tSt";
        // const r = new Date().getTime();
        //const CUSUT = cookies.get('CUSUT') || '';
        // const s = md5(r+mtoken+ENCRYPT_KEY).toLowerCase();

        // config.headers.r = r;
        // config.headers.s = s;

        // if(config.data){
        //     config.data.r = r;
        //     config.data.s = s;
        // }else{
        //     config.params.r = r;
        //     config.params.s = s;
        // }

        return config;
    },
    error => {
        // Do something with request error
        Promise.reject(error);
    }
);

// response interceptor
service.interceptors.response.use(
    res => {

        //Vue.set(res.data,'state',res.data.code == 200 ? true : false);

        // Stream file
        if (res.request.responseType === 'blob' && res.data instanceof Blob) {
            return res;
        }

        // Not login
        const failState = ['token.empty','user.login.status.no','USER_NOT_LOGIN_DEFAULT'];
        const  path =  router.currentRoute.path;
        const  fullPath =  router.currentRoute.fullPath;
        if (failState.includes(res.data.state) &&  path !== '/login') {
            store.commit('logOut');
            router.push({
                path: '/login',
                query: { redirect: fullPath }
            })
        }
        const errorText = res.data.msg || codeMessage[res.status]; 
        if(res.status != 200){
            res.data.msg = errorText;
        }

        if( res.data.state == "illegal_character" ){
            MessageBox.confirm(errorText, '警告', {
                confirmButtonText: '知道了',
                showCancelButton: false,
                dangerouslyUseHTMLString: true
            })   
            return Promise.reject(false);
        }

        return res;
    },
    error => {
        let keys = Object.keys(codeMessage) , msg = "";
        keys.forEach(key => {
            if (error.message.includes(key)) msg = codeMessage[key];
        });
        if(msg) return {data: {state: 0, msg: msg}};
        
        // 过滤请求超时并显示提示
        if (/timeout/.test(error.message)) return {data: {state: 0, msg: '请求超时请重新尝试~'}};
        return Promise.reject(error);
    }
);

export default service;
