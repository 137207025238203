<template>
    <div id="app">
        <router-view :key="$route.fullPath" />
    </div>
</template>

<script>
import ls from 'local-storage';
import cookie from 'js-cookie';
var md5 = require('js-md5');
import {getMenuList} from '@/assets/js/commonApi';

export default {
    name: "App",
    computed:{
        userInfo(){
            return this.$store.state.userInfo || ls.get("userInfo");
        },
        menuList(){
            return this.$store.state.menuList || ls.get("menuList");
        }
    },
    created(){
        const _token = ls.get('token');
        if(!_token){
            ls.set('token',md5( this.$utils.uuid(32).toLowerCase() ))
        }

        const _sign = cookie.get('CUSUT');

        if( _sign ){
            this.$store.commit('setUserInfo',this.userInfo);
        }


        if( _sign && !this.menuList){
            getMenuList();
        }else{
            this.$store.commit('setMenuList',this.menuList);
        }
    }
};
</script>

<style lang="scss">
// @import "@/assets/iconfont/iconfont.css";
@import "@/assets/css/common.scss";
</style>
