// 注册路由管理
export default{
    path: '/register',
    name: 'register',
    component: () => import('@/layout/default.vue'),
    redirect: '/register/index',
    meta: {
        requireAuth: false
    },
    children:[{
        path:'index',
        component: () => import('@/views/register/index.vue'),
    },{
        path:'claim',
        component: () => import('@/views/register/claim.vue'),
    },{
        path:'step1',
        component: () => import('@/views/register/step1.vue'),
    },{
        path:'step2',
        component: () => import('@/views/register/step2.vue'),
    }]
}