import Vue from 'vue'
import Vuex from 'vuex'
import ls from 'local-storage'
import cookie from 'js-cookie'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    CUSUT: null, //登录用户签名
    userInfo: null, //登录用户信息
    menuList: null, //菜单
    priviList: null, //权限
    taskList: [],//上传任务
  },
  mutations: {
    setCUSUT(state,data){
      state.CUSUT = data;
      cookie.set('CUSUT',data);
    },
    setUserInfo(state,data){
      state.userInfo = data;
      ls.set('userInfo',data);
    },
    setMenuList(state, data) {
      state.menuList = data;
      ls.set('menuList',data);
    },
    setPriviList(state,data){
      state.priviList = data;
      ls.set('priviList',data);
    },
    logOut(state){
      state.CUSUT = null;
      state.userInfo = null;
      state.menuList = null;
      cookie.remove('CUSUT');
      ls.remove('userInfo');
      ls.remove('menuList');
    },
    //上传任务
    SET_TASKLIST: (state, data) => {
      state.taskList = data;
    },
  },
  actions: {
    //上传任务
    setTaskList({commit}, task) {
      commit('SET_TASKLIST', task);
    },
  },
  modules: {
  }
})
